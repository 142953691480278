/* global ymaps*/
import React, { Suspense } from "react";
import axios from "axios";
import "./App.css";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { contact } from "../../context/ContactsContext";
import { cases } from "../../context/CaseContext";
import logoload from "../../images/logoload.png";
import logowebpload from "../../images/logoload.webp";
import logoavifload from "../../images/logoload.avif";
import placemark from "../../images/icon/placemark.png";
import { Helmet } from "react-helmet";

const Home = React.lazy(() => import("../Home/Home"));
const Thanks = React.lazy(() => import("../Thanks/Thanks"));
const Error = React.lazy(() => import("../Error/Error"));

function App() {
  const [LinkActive, setLinkActive] = React.useState(null);
  const [isLoad, setLoad] = React.useState(false);
  const [isTimer, setTimer] = React.useState(false);
  const [isThanks, setThanks] = React.useState(false);
  const [isFile, setFile] = React.useState(false);
  const [isYaclid, setYaclid] = React.useState();
  const [isVisitorId, setVisitorId] = React.useState();
  const [isActiveType, setActiveType] = React.useState(false);
  const [isActiveMap, setActiveMap] = React.useState(true);
  const [isOpenPopupForm, setOpenPopupForm] = React.useState(false);
  const [isOpenPopupFormCall, setOpenPopupFormCall] = React.useState(false);
  const [isOpenPopupImg, setOpenPopupImg] = React.useState(false);
  const [isOpenPopupCalc, setOpenPopupCalc] = React.useState(false);
  const [isOpenPopupClose, setOpenPopupClose] = React.useState(false);
  const [isOpenPopupQuestion, setOpenPopupQuestion] = React.useState(false);
  const [isOpenPopupPolicy, setOpenPopupPolicy] = React.useState(false);
  const [isBig, setBig] = React.useState(false);
  const [isPopupImg, setPopupImg] = React.useState();
  const [radio, setRadio] = React.useState("Нет");
  const [isName, setName] = React.useState("газобетон");
  const [isCaseKey, setCaseKey] = React.useState();
  const [isMainFoto, setMainFoto] = React.useState();
  const [isMainFotoWebp, setMainFotoWebp] = React.useState();
  const [isMainFotoAvif, setMainFotoAvif] = React.useState();
  const [isCaseSrc, setCaseSrc] = React.useState();
  const [isCaseTitle, setCaseTitle] = React.useState();
  const [isCaseText, setCaseText] = React.useState();
  const [isCaseType, setCaseType] = React.useState();
  const [isCase, setCase] = React.useState(false);
  const [isContacts, setContacts] = React.useState(false);
  const [slide, setSlide] = React.useState([1, 2, 3, 4]);
  const [slideBig, setSlideBig] = React.useState(1);
  const [stage, setStage] = React.useState(1);
  const [sale, setSale] = React.useState(true);
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
  });
  const history = useNavigate();
  const location = useLocation();
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
    });
  };
  window.AMOPIXEL_IDENTIFIER_PARAMS = window.AMOPIXEL_IDENTIFIER_PARAMS || {};
  window.AMOPIXEL_IDENTIFIER_PARAMS.onload = function (pixel_identifier) {
    var visitor_uid = pixel_identifier.getVisitorUid(); // Получаем visitor_uid
    if (visitor_uid) {
      setVisitorId(visitor_uid);
    }
  };
  React.useEffect(() => {
    window.ym(93548999, "hit", location.pathname);
  }, [location]);
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  React.useEffect(() => {
    setTimeout(function () {
      setTimer(true);
    }, 30000);
  }, []);
  React.useEffect(() => {
    if (isTimer && sale) {
      setOpenPopupClose(true);
      setStage(1);
      setSale(false);
    }
  }, [isTimer, sale]);
  React.useEffect(() => {
    setSlide([1, 2, 3, 4]);
    setCaseKey(cases.filter((p) => p.type === isName)[0].key);
    setMainFoto(cases.filter((p) => p.type === isName)[0].srcMain);
    setMainFotoWebp(cases.filter((p) => p.type === isName)[0].srcMainWebp);
    setMainFotoAvif(cases.filter((p) => p.type === isName)[0].srcMainAvif);
    setCaseSrc(cases.filter((p) => p.type === isName)[0].src);
    setCaseTitle(cases.filter((p) => p.type === isName)[0].title);
    setCaseText(cases.filter((p) => p.type === isName)[0].text);
    setCaseType(cases.filter((p) => p.type === isName)[0].type);
  }, [isName]);
  React.useEffect(() => {
    setPopupImg({
      webp: contact[slideBig].webp,
      avif: contact[slideBig].avif,
      ordinary: contact[slideBig].src,
    });
  }, [slideBig]);
  React.useEffect(() => {
    var script = document.createElement("script");
    script.src =
      "https://api-maps.yandex.ru/2.1?apikey=64abed67-8695-40be-b964-8d052a5a06eb&lang=ru-RU&load=Map,Placemark,behavior.ScrollZoom";
    script.type = "text/javascript";
    script.onload = function () {
      setLoad(true);
      setYaclid(readCookie("yclid"));
      function init(ymaps) {
        var myMap = new ymaps.Map("map", {
          center:
            dimensions.width <= 768
              ? [59.8842, 30.365959]
              : [59.885982, 30.359959],
          zoom: 16,
          type: "yandex#map",
        });
        var myPlacemark = new ymaps.Placemark(
          [59.885982, 30.366048],
          {},
          {
            iconLayout: "default#image",
            iconImageHref: `${placemark}`,
            iconImageSize: [89, 112],
            iconImageOffset: [-43, -90],
          }
        );
        function disabledMap() {
          myMap.behaviors.disable("scrollZoom");
          myMap.behaviors.disable("drag");
          setActiveMap(true);
        }
        function enabledMap() {
          myMap.behaviors.enable("scrollZoom");
          myMap.behaviors.enable("drag");
          setActiveMap(false);
        }
        disabledMap();
        myMap.geoObjects.add(myPlacemark);
        window.addEventListener("keydown", (e) => {
          e.key === "Control" ? enabledMap() : disabledMap();
        });
        window.addEventListener("keyup", (e) => {
          disabledMap();
        });
        window.addEventListener("touchstart", (e) => {
          e.touches.length > 1 ? enabledMap() : disabledMap();
        });
        window.addEventListener("touchend", (e) => {
          disabledMap();
        });
      }
      ymaps.ready(init);
    };
    setTimeout(function () {
      document.head.appendChild(script);
    }, 3000);
  }, []);
  function readCookie(name) {
    var n = name + "=";
    var cookie = document.cookie.split(";");
    for (var i = 0; i < cookie.length; i++) {
      var c = cookie[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(n) == 0) {
        return c.substring(n.length, c.length);
      }
    }
    return null;
  }
  function onClose() {
    setOpenPopupForm(false);
    setOpenPopupFormCall(false);
    setOpenPopupImg(false);
    setOpenPopupCalc(false);
    setOpenPopupClose(false);
    setOpenPopupQuestion(false);
    setOpenPopupPolicy(false);
    setBig(false);
    setPopupImg("");
    setCase(false);
    setContacts(false);
    setStage(1);
    sale && isOpenPopupClose ? setSale(false) : <></>;
    isOpenPopupCalc ? setActiveType(false) : <></>;
    isOpenPopupClose ? setActiveType(false) : <></>;
  }
  function onSubmitForm(body) {
    let formData = new FormData();
    Object.entries(body).forEach((element) => {
      const [key, value] = element;
      formData.append(key, value);
    });
    axios({
      method: "POST",
      url: "./send.php",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then(function (response) {
        if (response.data.result === "success") {
          setThanks(true);
          history("/thank-you");
          onClose();
          setSale(false);
        }
      })
      .then(() => {
        axios({
          method: "GET",
          url: "./select.php",
          headers: {
            "Access-Control-Allow-Headers": "content-type",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            axios({
              method: "POST",
              url: "./auth.php",
              headers: {
                "Access-Control-Allow-Headers": "content-type",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              data: { token: res.data },
            }).then((res) => {
              axios({
                method: "POST",
                url: "./update.php",
                headers: {
                  "Access-Control-Allow-Headers": "content-type",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                data: { token: res.data.refresh_token },
              });
              axios({
                method: "POST",
                url: "./leads.php",
                headers: {
                  "Access-Control-Allow-Headers": "content-type",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${res.data.access_token}`,
                },
                data: {
                  visitor: isVisitorId,
                  tel: body.tel,
                  name: body.name,
                  mail: body.mail,
                  price: body.price,
                  metr: body.metr,
                  kilometr: body.kilometr,
                  question: body.question,
                  token: res.data.access_token,
                  yclid: isYaclid,
                },
              });
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }
  function slidePlus() {
    if (slide[0] >= isCaseSrc.length) {
      setSlide([1, 2, 3, 4]);
    } else {
      if (slide[1] >= isCaseSrc.length) {
        setSlide([isCaseSrc.length, 1, 2, 3]);
      } else {
        if (slide[2] >= isCaseSrc.length) {
          setSlide([isCaseSrc.length - 1, isCaseSrc.length, 1, 2]);
        } else {
          if (slide[3] >= isCaseSrc.length) {
            setSlide([
              isCaseSrc.length - 2,
              isCaseSrc.length - 1,
              isCaseSrc.length,
              1,
            ]);
          } else {
            setSlide([slide[0] + 1, slide[1] + 1, slide[2] + 1, slide[3] + 1]);
          }
        }
      }
    }
  }
  function slideMinus() {
    if (slide[0] <= 1) {
      setSlide([isCaseSrc.length, 1, 2, 3]);
    } else {
      if (slide[1] <= 1) {
        setSlide([isCaseSrc.length - 1, isCaseSrc.length, 1, 2]);
      } else {
        if (slide[2] <= 1) {
          setSlide([
            isCaseSrc.length - 2,
            isCaseSrc.length - 1,
            isCaseSrc.length,
            1,
          ]);
        } else {
          if (slide[3] <= 1) {
            setSlide([
              isCaseSrc.length - 3,
              isCaseSrc.length - 2,
              isCaseSrc.length - 1,
              isCaseSrc.length,
            ]);
          } else {
            setSlide([slide[0] - 1, slide[1] - 1, slide[2] - 1, slide[3] - 1]);
          }
        }
      }
    }
  }
  // Hook
  const useOnScreen = (options) => {
    const ref = React.useRef();
    const [visible, setVisible] = React.useState(false);
    React.useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        }
      }, options);
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, options]);
    return [ref, visible];
  };
  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              isCase={isCase}
              isContacts={isContacts}
              logoload={logoload}
              logowebpload={logowebpload}
              logoavifload={logoavifload}
              LinkActive={LinkActive}
              setLinkActive={setLinkActive}
              setOpenPopupForm={setOpenPopupForm}
              setOpenPopupFormCall={setOpenPopupFormCall}
              setStage={setStage}
              setOpenPopupCalc={setOpenPopupCalc}
              isLoad={isLoad}
              useOnScreen={useOnScreen}
              dimensions={dimensions}
              setPopupImg={setPopupImg}
              setOpenPopupImg={setOpenPopupImg}
              setOpenPopupPolicy={setOpenPopupPolicy}
              onSubmitForm={onSubmitForm}
              isMainFoto={isMainFoto}
              isMainFotoWebp={isMainFotoWebp}
              isMainFotoAvif={isMainFotoAvif}
              isCaseKey={isCaseKey}
              setMainFoto={setMainFoto}
              setMainFotoWebp={setMainFotoWebp}
              setMainFotoAvif={setMainFotoAvif}
              isCaseSrc={isCaseSrc}
              isCaseTitle={isCaseTitle}
              isCaseText={isCaseText}
              isCaseType={isCaseType}
              isName={isName}
              setName={setName}
              setCase={setCase}
              slide={slide}
              setSlide={setSlide}
              slideMinus={slideMinus}
              slidePlus={slidePlus}
              stage={stage}
              radio={radio}
              setRadio={setRadio}
              isActive={isActiveType}
              setActive={setActiveType}
              isFile={isFile}
              setOpenPopupQuestion={setOpenPopupQuestion}
              setSlideBig={setSlideBig}
              setContacts={setContacts}
              isActiveMap={isActiveMap}
              onClose={onClose}
              isOpen={isOpenPopupForm}
              isOpenPopupFormCall={isOpenPopupFormCall}
              isOpenPopupForm={isOpenPopupForm}
              isOpenPopupQuestion={isOpenPopupQuestion}
              isOpenPopupCalc={isOpenPopupCalc}
              isOpenPopupPolicy={isOpenPopupPolicy}
              isOpenPopupImg={isOpenPopupImg}
              isOpenPopupClose={isOpenPopupClose}
              setFile={setFile}
              isBig={isBig}
              setBig={setBig}
              isPopupImg={isPopupImg}
              sale={sale}
              setSale={setSale}
              setOpenPopupClose={setOpenPopupClose}
            >
              <Helmet>
                <meta
                  name="description"
                  content="Монтаж водяного теплого пола ПОД КЛЮЧ, монтаж отопления, водоснабжения, канализации. Монтаж котельных."
                />
                <title>
                  Монтаж отопления, водоснабжения и канализации "ПОД КЛЮЧ" в
                  загородных дома по Санкт-Петербургу и ЛО
                </title>
                <link rel="canonical" href="https://homitec.spb.ru/" />
                <meta name="robots" content="index,follow" />
                <meta name="keywords" content="" />
                <meta
                  property="og:title"
                  content='Монтаж отопления, водоснабжения и канализации "ПОД КЛЮЧ" в загородных дома по Санкт-Петербургу и ЛО'
                />
                <meta
                  property="og:description"
                  content="Монтаж водяного теплого пола ПОД КЛЮЧ, монтаж отопления, водоснабжения, канализации. Монтаж котельных."
                />
              </Helmet>
            </Home>
          }
        >
          <Route
            path="/thank-you"
            element={
              isThanks ? (
                <Thanks setOpenPopupForm={setOpenPopupFormCall}>
                  <Helmet>
                    <meta
                      name="description"
                      content="Спасибо за заявку, мы свяжемся с Вами в ближайшее время!"
                    />
                    <title>Спасибо</title>
                    <link rel="canonical" href="https://homitec.spb.ru/" />
                    <meta name="robots" content="none" />
                    <meta name="keywords" content="" />
                    <meta property="og:title" content="Спасибо" />
                    <meta
                      property="og:description"
                      content="Спасибо за заявку, мы свяжемся с Вами в ближайшее время!"
                    />
                  </Helmet>
                </Thanks>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Error>
              <Helmet>
                <meta
                  name="description"
                  content="Ошибка 404. К сожалению такой страницы не существует. Возможно она была перемещена, или Вы просто неверно указали адрес страницы."
                />
                <title>404</title>
                <link rel="canonical" href="https://homitec.spb.ru/" />
                <meta name="robots" content="none" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="404" />
                <meta
                  property="og:description"
                  content="Ошибка 404. К сожалению такой страницы не существует. Возможно она была перемещена, или Вы просто неверно указали адрес страницы."
                />
              </Helmet>
            </Error>
          }
        />
      </Routes>
    </Suspense>
  );
}

export default App;
